import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Banner, Breadcrumb, Title } from 'components'

export default function IndiceDocumenti() {
  return (
    <>
      <Banner />
      <Title title="Documenti tecnico scientifici" />
      <Breadcrumb current="Documenti tecnico scientifici" />
      <Typography variant="h2">Seleziona un prodotto</Typography>
      <br />
      <Link
        to={'documenti?product=ACRONELLE'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Acronelle</Typography>
      </Link>
      <br />
      <Link
        to={'documenti?product=ADOMELLE'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Adomelle</Typography>
      </Link>
      <br />
      <Link
        to={'documenti?product=BIFISELLE'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Bifiselle</Typography>
      </Link>
      <br />
      <Link
        to={'documenti?product=CITOGENEX'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Citogenex</Typography>
      </Link>
      <br />
      {/* <Link
        to={'documenti?product=Crispagyn'}
        style={{ textDecoration: 'none' }}
      > */}
      <Typography variant="h4">Crispagyn (pagina in aggiornamento)</Typography>
      {/* </Link> */}
      <br />
      <Link
        to={'documenti?product=ENTERELLE'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Enterelle</Typography>
      </Link>
      <br />
      {/* <Link to={'documenti?product=FEMELLE'} style={{ textDecoration: 'none' }}> */}
      <Typography variant="h4">Femelle (pagina in aggiornamento)</Typography>
      {/* </Link> */}
      <br />
      <Link to={'documenti?product=PILOREX'} style={{ textDecoration: 'none' }}>
        <Typography variant="h4">Pilorex</Typography>
      </Link>
      <br />
      <Link
        to={'documenti?product=RAMNOSELLE'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Ramnoselle</Typography>
      </Link>
      <br />
      <Link
        to={'documenti?product=SEROBIOMA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Serobioma</Typography>
      </Link>
      <br />
    </>
  )
}
