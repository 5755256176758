import React from 'react'
import { useMutation } from '@apollo/client'
import {
  getDocuments_getDocuments_documents as DocumentType,
  updateDocument,
  updateDocumentVariables,
  createDocument,
  createDocumentVariables,
  MediaType,
  Product,
} from 'types/generated/schemaTypes'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CREATE_DOCUMENT, UPDATE_DOCUMENT } from 'mutation'
import { ActionType, DialogForm, FileUpload } from 'components'
import { formatError } from 'utils'

export interface DocumentFormValues {
  title: string
  cover: string
  url: string | null
  highlighted: boolean
  products: string[]
}

const defaultInitialValues = {
  title: '',
  cover: '',
  url: '',
  highlighted: false,
  products: [],
}

const parseInitialValue = (input: DocumentType): DocumentFormValues => {
  const values: DocumentFormValues = {
    title: input.title,
    cover: input.cover || '',
    url: input.url,
    highlighted: input.highlighted,
    products: input.products || [],
  }
  return values
}

const validationSchema = Yup.object({
  title: Yup.string().required('Campo obbligatorio'),
  cover: Yup.string().required('Campo obbligatorio'),
  url: Yup.string().required('Campo obbligatorio'),
})

interface DocumentFormProps {
  open: boolean
  onClose: () => void
  onCreate: () => void
  onUpdate: () => void
  document: DocumentType | null
}

const EventForm: React.FC<DocumentFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  document,
}) => {
  const [createDocumentMutation, { error: createError }] = useMutation<
    createDocument,
    createDocumentVariables
  >(CREATE_DOCUMENT, {
    onCompleted: async (data) => {
      formik.resetForm()
      onCreate()
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })
  const [updateDocumentMutation, { error: updateError }] = useMutation<
    updateDocument,
    updateDocumentVariables
  >(UPDATE_DOCUMENT, {
    onCompleted: async (data) => {
      formik.resetForm()
      onCreate()
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const update = !!document
  const formik = useFormik<DocumentFormValues>({
    initialValues: document
      ? parseInitialValue(document as DocumentType)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: createDocumentVariables | updateDocumentVariables
      if (!update) {
        // create
        variables = {
          documentData: {
            title: values.title,
            cover: values.cover !== '' ? values.cover : null,
            highlighted: values.highlighted,
            url: values.url !== '' ? values.url : null,
            products: values.products,
          },
        } as createDocumentVariables
        await createDocumentMutation({ variables })
      } else {
        // update
        variables = {
          documentData: {
            title: values.title,
            cover: values.cover !== '' ? values.cover : null,
            highlighted: values.highlighted,
            url: values.url !== '' ? values.url : null,
            products: values.products,
          },
          documentId: (document as DocumentType).id,
        } as updateDocumentVariables
        console.log('update')
        await updateDocumentMutation({ variables })
      }
    },
  })

  return (
    <DialogForm
      open={open}
      title={update ? 'Modifica documento' : 'Crea documento'}
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: 'ESCI SENZA SALVARE',
          callback: () => {
            formik.resetForm()
            onClose()
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: 'SALVA E CHIUDI',
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: 'hidden', paddingTop: 10 }}>
        <Grid container spacing={4}>
          {/* IN EVIDENZA */}
          <Grid item xs={12} md={12}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlLabel
                label="In evidenza"
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.highlighted}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    name="highlighted"
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* TITOLO */}
            <FormControl fullWidth={true}>
              <TextField
                name="title"
                error={formik.touched.title && !!formik.errors.title}
                helperText={
                  formik.touched.title &&
                  !!formik.errors.title &&
                  formik.errors.title
                }
                multiline={true}
                variant="outlined"
                id="title"
                size="small"
                label="Titolo*"
                value={formik.values.title}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FileUpload
              id="document-cover"
              onChange={(filename) => {
                formik.setFieldValue('cover', filename)
              }}
              label="Carica la cover (formato 365x512)"
              labelSmall="Cover"
              url={formik.values.cover}
              media={MediaType.DOCUMENT_COVER}
            />
            {formik.touched.cover && !!formik.errors.cover && (
              <FormHelperText error={true} variant="standard">
                {formik.errors.cover}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FileUpload
              id="url"
              onChange={(filename) => {
                formik.setFieldValue('url', filename)
              }}
              label="Carica il pdf"
              labelSmall="Pdf"
              url={formik.values.url}
              media={MediaType.DOCUMENT_FILE}
            />
            {formik.touched.url && !!formik.errors.url && (
              <FormHelperText error={true} variant="standard">
                {formik.errors.url}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {/* PRODOTTI */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={formik.touched.products && !!formik.errors.products}
                id="products-label"
              >
                Prodotti
              </InputLabel>
              <Select
                multiple
                name="products"
                value={formik.values.products}
                labelId="status-label"
                id="status"
                label="Prodotti"
                error={formik.touched.products && !!formik.errors.products}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                // style={{ marginBottom: 20 }}
              >
                {Object.keys(Product).map((product) => (
                  <MenuItem value={product}>{product}</MenuItem>
                ))}
              </Select>
              {formik.touched.products && !!formik.errors.products && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.products}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        {(createError || updateError) && (
          <Typography color="error" variant="body1">
            {createError && formatError(createError)}
            {updateError && formatError(updateError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  )
}

export default EventForm
