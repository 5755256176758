import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Grid,
  FormControl,
  Hidden,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Search as SearchIcon } from '@material-ui/icons'
import { GET_DOCUMENTS } from 'query'
import {
  getDocuments,
  getDocumentsVariables,
  DocumentWhereInput,
  DocumentOrderByInput,
  Product,
} from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  DocumentItem,
  Title,
  NoResults,
  FiltersWrapper,
} from 'components'
import { useLocation } from 'react-router-dom'

const useStyle = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  preview: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        padding: '65px 105px 60px 105px',
      },
    },
  },
  search: {
    '& .MuiInputBase-root': {
      borderRadius: 20,
      backgroundColor: '#ffffff',
    },
  },
}))

function useQueryString() {
  return new URLSearchParams(useLocation().search)
}

function isValidProduct(prodotto: string): boolean {
  const values = Object.values(Product)
  return values.includes((prodotto as unknown) as Product)
}

export default function GestioneUtenti() {
  const classes = useStyle()

  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState<DocumentOrderByInput>(
    DocumentOrderByInput.TITLE_ASC
  )

  const query = useQueryString()
  const _product = query.get('product') || 'ALL'

  const [product, setProduct] = useState<Product | 'ALL'>(
    isValidProduct(_product) ? (_product as Product) : 'ALL'
  )

  const [searchString, setSearchString] = useState('')

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * 12)
    setPage(value)
  }
  const where: DocumentWhereInput = {}

  if (product !== 'ALL') where.product = product
  if (searchString.length > 2) where.searchPattern = searchString
  const {
    data: documentsData,
    loading: documentsLoading,
    error: documentsError,
  } = useQuery<getDocuments, getDocumentsVariables>(GET_DOCUMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderby: orderBy,
      offset,
      limit: 12,
    },
  })

  return (
    <>
      <Title title="Documenti scientifici" />
      <Breadcrumb current="Documenti scientifici" />
      <div>
        <FiltersWrapper>
          <Grid container spacing={4} alignItems="flex-end">
            <Grid item xs={6} md={3}>
              <FormControl fullWidth={true} variant="standard" size="small">
                <InputLabel id="order-label">Ordina per</InputLabel>
                <Select
                  name="order"
                  value={orderBy}
                  labelId="order-label"
                  id="order"
                  label="Ordina per"
                  onChange={(e) => {
                    setOffset(0)
                    setPage(1)
                    setOrderBy(e.target.value as DocumentOrderByInput)
                  }}
                >
                  <MenuItem value={DocumentOrderByInput.CREATION_ASC}>
                    Data creazione ↑
                  </MenuItem>
                  <MenuItem value={DocumentOrderByInput.CREATION_DESC}>
                    Data creazione ↓
                  </MenuItem>
                  <MenuItem value={DocumentOrderByInput.TITLE_ASC}>
                    Alfabetico ↑
                  </MenuItem>
                  <MenuItem value={DocumentOrderByInput.TITLE_DESC}>
                    Alfabetico ↓
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Hidden only={['xs', 'sm']}>
              <Grid item xs={12} md={4}>
                &nbsp;
              </Grid>
            </Hidden>
            <Grid item xs={12} md={5}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                size="small"
                className={classes.search}
              >
                <InputLabel htmlFor="search">Cerca</InputLabel>
                <OutlinedInput
                  name="searchString"
                  id="searchString"
                  label="Cerca"
                  autoFocus={searchString.length > 2}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </FiltersWrapper>
        {documentsLoading && <div>Caricamento degli eventi in corso</div>}
        {!documentsLoading && !documentsError && documentsData && (
          <>
            <Grid container spacing={4}>
              {documentsData.getDocuments.documents.map((document) => (
                <Grid item xs={6} md={4} style={{ display: 'flex' }}>
                  <DocumentItem key={document.id} document={document} />
                </Grid>
              ))}
            </Grid>
            {documentsData.getDocuments.documents.length === 0 && <NoResults />}
            {documentsData.getDocuments.documents.length > 0 && (
              <div
                style={{
                  marginTop: 30,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  count={Math.ceil(documentsData.getDocuments.total / 12)}
                  // count={1}
                  page={page}
                  color="primary"
                  onChange={handleChangePage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
