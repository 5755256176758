import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import {
  AttivazioneUtenti,
  Contattaci,
  Documenti,
  Documento,
  Eventi,
  EventiArchiviati,
  EventiAreaGeografica,
  EventiInPresenza,
  EventiPersonali,
  EventiWebinar,
  Evento,
  GestioneDocumenti,
  GestioneEventi,
  GestioneOperatori,
  GestioneUtenti,
  Home,
  HomeInterna,
  Profilo,
} from 'routes'

import { PageLayout } from 'components'
import { isLoggedInVar, roleVar } from 'cache'
import { UserRole } from 'types/generated/schemaTypes'
import IndiceDocumenti from 'routes/indice-documenti'

interface ProtectedRouteProps extends RouteProps {
  withLayout?: boolean
  roleAccess?: UserRole[]
  large?: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar)
  // const role = useReactiveVar(roleVar)
  if (isLoggedIn) {
    if (props.roleAccess) {
      if (!props.roleAccess.includes(roleVar())) {
        return <Redirect to={{ pathname: '/' }} />
      }
    }
    if (props.withLayout) {
      return (
        <PageLayout large={props.large ? true : false}>
          <Route {...props} />
        </PageLayout>
      )
    } else {
      return <Route {...props} />
    }
  } else {
    const renderComponent = () => <Redirect to={{ pathname: '/login' }} />
    return <Route {...props} component={renderComponent} render={undefined} />
  }
}

const RouteManager: React.FC = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar)
  const role = useReactiveVar(roleVar)

  // eventi
  // gestione-eventi
  // gestione-eventi:id
  // utenti
  // utenti:id
  // operatori
  // operatori:id
  // documenti
  // contatti
  // settings
  // miei-eventi

  return (
    <Router>
      {isLoggedIn ? (
        <>
          <Switch>
            <ProtectedRoute
              path="/eventi/:id"
              component={Evento}
              withLayout={true}
              roleAccess={[UserRole.PROFESSIONAL, UserRole.ADMIN]}
            />
            <ProtectedRoute
              roleAccess={[UserRole.PROFESSIONAL]}
              path="/eventi"
              component={Eventi}
              withLayout={true}
            />
            <ProtectedRoute
              roleAccess={[UserRole.PROFESSIONAL]}
              path="/eventi-area-geografica"
              component={EventiAreaGeografica}
              withLayout={true}
            />
            <ProtectedRoute
              roleAccess={[UserRole.PROFESSIONAL]}
              path="/eventi-in-presenza"
              component={EventiInPresenza}
              withLayout={true}
            />
            <ProtectedRoute
              roleAccess={[UserRole.PROFESSIONAL]}
              path="/eventi-webinar"
              component={EventiWebinar}
              withLayout={true}
            />
            <ProtectedRoute
              roleAccess={[UserRole.PROFESSIONAL]}
              path="/eventi-archiviati"
              component={EventiArchiviati}
              withLayout={true}
            />
            <ProtectedRoute
              roleAccess={[UserRole.PROFESSIONAL]}
              path="/contatti"
              component={Contattaci}
              withLayout={true}
            />
            <ProtectedRoute
              path="/gestione-eventi"
              component={GestioneEventi}
              large={false}
              roleAccess={[UserRole.ADMIN, UserRole.OPERATOR]}
              withLayout={true}
            />
            <ProtectedRoute
              roleAccess={[UserRole.PROFESSIONAL]}
              path="/eventi-personali"
              component={EventiPersonali}
              withLayout={true}
            />
            <ProtectedRoute
              path="/attivazione-utenti"
              component={AttivazioneUtenti}
              roleAccess={[UserRole.ADMIN, UserRole.OPERATOR]}
              withLayout={true}
            />
            <ProtectedRoute
              path="/gestione-utenti"
              roleAccess={[UserRole.ADMIN, UserRole.OPERATOR]}
              component={GestioneUtenti}
              withLayout={true}
            />
            <ProtectedRoute
              path="/gestione-documenti"
              roleAccess={[UserRole.ADMIN, UserRole.OPERATOR]}
              component={GestioneDocumenti}
              withLayout={true}
            />
            <ProtectedRoute
              path="/gestione-operatori"
              component={GestioneOperatori}
              roleAccess={[UserRole.ADMIN]}
              withLayout={true}
            />
            <ProtectedRoute
              path="/profilo"
              component={Profilo}
              withLayout={true}
            />
            <ProtectedRoute
              path="/documenti/:id"
              component={Documento}
              withLayout={true}
              roleAccess={[
                // UserRole.PROFESSIONAL,
                UserRole.OPERATOR,
                UserRole.ADMIN,
              ]}
            />
            <ProtectedRoute
              path="/documenti"
              component={Documenti}
              withLayout={true}
              roleAccess={[
                // UserRole.PROFESSIONAL,
                UserRole.OPERATOR,
                UserRole.ADMIN,
              ]}
            />
            <ProtectedRoute
              path="/home-interna"
              component={HomeInterna}
              withLayout={true}
              roleAccess={[
                UserRole.PROFESSIONAL,
                UserRole.OPERATOR,
                UserRole.ADMIN,
              ]}
            />
            <ProtectedRoute
              path="/indice-documenti"
              component={IndiceDocumenti}
              withLayout={true}
              roleAccess={[
                // UserRole.PROFESSIONAL,
                UserRole.OPERATOR,
                UserRole.ADMIN,
              ]}
            />
            <Redirect
              from="/"
              to={
                role === UserRole.PROFESSIONAL
                  ? // ? 'home-interna' // DA RIATTIVARE
                    'eventi'
                  : 'gestione-eventi'
              }
            />
          </Switch>
        </>
      ) : (
        <Switch>
          {/* <Route path="/requestToken" component={RequestToken} />
          <Route path="/resetPassword" component={ResetPassword} />
          <Route path="/init" component={RegisterAdmin} /> */}
          <Route path="/reset-password" component={Home}></Route>
          <Route path="/login" component={Home}></Route>
          <Redirect from="/" to="/login" />
        </Switch>
      )}
    </Router>
  )
}

export default RouteManager
